<template>
    <el-dialog
            title="数据权限"
            :close-on-click-modal="false"
            width="50%"
            :visible.sync="visible">
        <el-form size="small" class="auth" :model="inputForm" status-icon v-loading="loading" ref="inputForm"
                 @keyup.enter.native="inputFormSubmit()"
                 @submit.native.prevent>
            <el-checkbox class="p_all2" v-model="checkStrictly"
                         @change="handleCheckedTreeConnect">父子级联动</el-checkbox>
            <el-scrollbar style="height: 400px">
                <el-tree
                        :data="menuList"
                        :props="defaultProps"
                        node-key="id"
                        ref="menuListTree"
                        show-checkbox :check-strictly="!checkStrictly" :expand-on-click-node="false" @check="changeData">
                             <div class="custom-tree-node" slot-scope="{ node, data }">
                                 <el-tooltip class="item" effect="dark" :content=" node.label" placement="top-start">
                                     <span class="label omit">{{ node.label }}</span>
                                 </el-tooltip>
                                <span>
                                    <el-checkbox v-model="data.queryPermissions"
                                                 @change="changeSingle(data, 'queryPermissions')">查看</el-checkbox>
                                    <el-checkbox v-model="data.downloadPermissions"
                                                 @change="changeSingle(data, 'downloadPermissions')">下载</el-checkbox>
                                    <el-checkbox v-model="data.printPermissions"
                                                 @change="changeSingle(data, 'printPermissions')">打印</el-checkbox>
                                    <el-checkbox v-model="data.transferQueryPermissions"
                                                 @change="changeSingle(data, 'transferQueryPermissions')">移交</el-checkbox>
                                </span>
                            </div>
                </el-tree>
            </el-scrollbar>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button v-if="!loading" type="primary" size="small" @click="inputFormSubmit()"
                       v-noMoreClick>保存
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                title: '',
                menuList: [],
                menuCheckedKeys: [],
                inputForm: {
                    id: '',
                    menuIds: '',
                },
                list: [],
                dataList: [],
                defaultProps: {
                    label: 'archivesBasicName',
                    children: 'children',
                },
                pageType: '',
                checkStrictly: false,
            }
        },
        methods: {
            // type  temporary临时权限
            init(id, type) {
                this.pageType = type
                this.inputForm.id = id
                this.list = []
                this.visible = true
                this.$nextTick(() => {
                    this.loading = true
                    this.$refs.inputForm.resetFields()
                    this.$refs.menuListTree.setCheckedKeys([])
                    // 查询所有数据
                    let p1 = this.$axios(this.api.record.selectArchivesBasicData)
                    // 查询回显数据
                    let api = this.api.record.getByRoleId
                    if (this.pageType == 'temporary') {
                        api = this.api.record.getBySysTemporaryAuthorityId
                    }
                    let p2 = this.$axios(api + '/' + this.inputForm.id)
                    Promise.all([p1, p2]).then((result) => {
                        this.menuList = result[0].data
                        let data = result[1]
                        if (data.status) {
                            this.loading = false
                            this.dataList = data.data
                            this.recursionDataEcho(this.menuList)
                        }
                    })
                })
            },
            // 递归数据回显
            recursionDataEcho(list) {
                list.forEach(data => {
                    this.dataList.map(item => {
                        if (item.archivesBasicDataId == data.id) {
                            this.$set(data, 'queryPermissions', item.queryPermissions)
                            this.$set(data, 'downloadPermissions', item.downloadPermissions)
                            this.$set(data, 'printPermissions', item.printPermissions)
                            this.$set(data, 'transferQueryPermissions', item.transferQueryPermissions)
                            this.$nextTick(() => {
                                this.setSinglePermissions(data, false)
                            })
                        }
                    })
                    if (data.children && data.children.length) {
                        this.recursionDataEcho(data.children);
                    }
                });
            },
            // 修改分类全选/全不选权限数据
            changeData(data, node) {
                let checkedFlag = node.checkedNodes.filter(item => item.id == data.id)
                if (checkedFlag.length) { // 全选
                    this.changeChecked(checkedFlag[0], true)
                    if (checkedFlag[0].children.length && this.checkStrictly) {
                        this.recursionChecked(checkedFlag[0].children, true)
                    }
                } else {  // 全不选
                    this.changeChecked(data, false)
                    if (data.children.length) {
                        this.recursionChecked(data.children, false)
                    }
                }
            },
            // 设置是否勾选
            changeChecked(value, flag, field) {
                if (field) {
                    this.$set(value, field, flag)
                    return
                }
                this.$set(value, 'queryPermissions', flag)
                this.$set(value, 'downloadPermissions', flag)
                this.$set(value, 'printPermissions', flag)
                this.$set(value, 'transferQueryPermissions', flag)
            },
            // 选择单个权限
            changeSingle(data, field) {
                if (data.children.length && this.checkStrictly) {
                    this.recursionChecked(data.children, data[field], field)
                }
                this.setSinglePermissions(data, true)
            },
            // 设置单个权限   children是否设置子节点
            setSinglePermissions(data, children) {
                // 单个都选
                if (data.queryPermissions && data.downloadPermissions && data.printPermissions && data.transferQueryPermissions) {
                    this.$refs.menuListTree.setChecked(data.id, true, children)
                }
                // 单个都不选
                if (!data.queryPermissions && !data.downloadPermissions && !data.printPermissions && !data.transferQueryPermissions) {
                    this.$refs.menuListTree.setChecked(data.id, false, children)
                }
                // 有一个没选
                if (!data.queryPermissions || !data.downloadPermissions || !data.printPermissions || !data.transferQueryPermissions) {
                    this.$refs.menuListTree.setChecked(data.id, false, children)
                }
            },
            // 递归循环设置是否选中
            recursionChecked(list, flag, field) {
                list.forEach(item => {
                    if (item.children && item.children.length) {
                        this.recursionChecked(item.children);
                    } else {
                        this.changeChecked(item, flag, field)
                    }
                });
            },
            // 递归循环menuList变为一级数组传给接口
            recursionData(list) {
                list.forEach(item => {
                    let obj = {
                        archivesBasicDataId: item.id,
                        queryPermissions: item.queryPermissions,
                        downloadPermissions: item.downloadPermissions,
                        printPermissions: item.printPermissions,
                        transferQueryPermissions: item.transferQueryPermissions,
                    }
                    if (item.children && item.children.length) {
                        this.list.push(obj)
                        this.recursionData(item.children);
                    } else {
                        this.list.push(obj)
                    }
                });
            },
            // 父子级联动
            handleCheckedTreeConnect(val) {
                this.checkStrictly = val
            },
            // 表单提交
            inputFormSubmit() {
                this.loading = true
                this.recursionData(this.menuList)
                this.$nextTick(() => {
                    // 查询回显数据
                    let api = this.api.record.rolePermissionsSave,
                        p = {
                            roleId: this.inputForm.id,
                        }
                    if (this.pageType == 'temporary') {
                        api = this.api.record.archivesrolepermissionstempSave
                        p = {
                            sysTemporaryAuthorityId: this.inputForm.id,
                        }
                    }
                    this.$axios(api, {
                        ...p,
                        archivesRolePermissionsList: this.list
                    }, 'post').then(data => {
                        this.loading = false
                        if (data.status) {
                            this.$message.success(data.msg)
                            this.visible = false
                            this.$emit('refreshDataList')
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })

            }
        }
    }
</script>

<style lang="scss" scoped>

    .el-tree {
        min-width: 100%;
        display: inline-block !important;
    }

    .auth {
        margin-top: -30px;
        margin-right: -19px;
        margin-bottom: -30px;
        margin-left: -19px;
    }

    .el-scrollbar {
        height: 100%;
    }

    .label {
        display: inline-block;
        margin-right: 20px;
        max-width: 22%;
        vertical-align: bottom;
    }
</style>
